
const enviroment = {
  environment: process.env.NODE_ENV,
  name: process.env.VUE_APP_NAME,
  company: process.env.VUE_APP_COMPANY,
  contact: process.env.VUE_APP_CONTACT,
  url: process.env.VUE_APP_TALLOS_APP_BASE_URL,
  api: process.env.VUE_APP_URL_MEGASAC_API,
};

const getLangOptions = () => {
  return [
    {
      value: "en-US",
      description: "English",
    },
    {
      value: "pt-BR",
      description: "Portuguese",
    },
  ];
};

/**
 * @description estilo padrão para tooltipsy
 */

const infoDefaultStyle = {
  padding: "50px",
  "max-width": "200px",
  "font-size": "12px",
  "font-weight": "bold",
  "text-align": "center",
  color: "#ffffff",
  background: "rgba(0,0,0,0.7)",
  border: "1px solid #ffffff",
  "font-family": '"Yanone Kaffeesatz", sans-serif',
  "letter-spacing": "2pt",
  "text-transform": "uppercase",
  opacity: "0.8",
  "text-shadow": "none",
};

/**
 * @description estilo padrão para tooltipsy
 */

const blockUIDefaultStyle = {
  overlayCSS: {
    border: "none",
    padding: "0px",
    margin: "0px",
    backgroundColor: "rgba(255,255,255,0.7)",
    opacity: 0.7,
  },
  css: {
    border: "none",
    padding: "0px",
    margin: "0px",
    background: "none",
    color: "#444",
  },
  message:
    '<span style="font-weight:bold;color:#cecece;"><img ondragstart="return false" style="height:220px !important;width:auto !important;opacity:0.4" src="images/loading.svg"/></span>',
};

/**
 * Configurações gerais do sistema
 *
 * _nota: está dessa forma para manter a compatibilidade do legado_
 */
export default {
  getLangOptions,
  infoDefaultStyle,
  blockUIDefaultStyle,
  ...enviroment
};
